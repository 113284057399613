<template>
  <div class="addTicket">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>{{
        id === "0" || id === 0 ? "添加门票" : "编辑门票"
      }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" :model="formList" label-width="80px">
      <el-form-item label="所属景区">
        <el-select
          clearable
          @clear="onClear"
          v-model="formList.sceneryId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in sceneryOptions"
            :key="item.sceneryId"
            :label="item.sceneryName"
            :value="item.sceneryId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="主标题" prop="ticketName">
        <el-input v-model="formList.ticketName" placeholder="请输入景区主标题"></el-input>
      </el-form-item>
      <el-form-item label="副标题" prop="ticketSecondName">
        <el-input
          v-model="formList.ticketSecondName"
          placeholder="请输入景区副标题"
        ></el-input>
      </el-form-item>
      <el-form-item label="标签" prop="ticketLabel">
        <el-input v-model="formList.ticketLabel" placeholder="请输入门票标签 "></el-input>
      </el-form-item>
      <el-form-item label="展示图" class="showImg" prop="showImg">
        <send-image
          type="one"
          :images="formList.showImg"
          @addImageStr="addImageStr"
          @addImageStrs="addImageStrs"
        ></send-image>

        <span style="color: red; margin-left: 20px"
          >每张图片不能超过500K，建议尺寸：150*150px</span
        >
      </el-form-item>
      <el-form-item label="分享图" class="showImg">
        <send-image
          type="one"
          :images="formList.shareImg"
          @addImageStr="addImageStrShare"
        
        ></send-image>

        <span style="color: red; margin-left: 20px"
          >每张图片不能超过500K，建议尺寸：150*150px</span
        >
      </el-form-item>
      <el-form-item label="所属分类">
        <el-select v-model="formList.cateId" placeholder="请选择">
          <el-option
            v-for="item in allCate"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否享受会员权益">
        <el-radio v-model="formList.isUser" :label="1">是</el-radio>
        <el-radio v-model="formList.isUser" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否退订">
        <el-radio v-model="formList.unsubscribe" :label="1">是</el-radio>
        <el-radio v-model="formList.unsubscribe" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否显示">
        <el-radio v-model="formList.isShow" :label="1">是</el-radio>
        <el-radio v-model="formList.isShow" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="万旅网是否显示">
        <el-radio v-model="formList.wlShow" :label="1">是</el-radio>
        <el-radio v-model="formList.wlShow" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否仅限新用户购买">
        <el-radio v-model="formList.isNew" :label="1">是</el-radio>
        <el-radio v-model="formList.isNew" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="支付方式">
        <el-select v-model="formList.sellWay" placeholder="请选择">
          <el-option
            v-for="item in sellWayList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所需积分" v-if="formList.sellWay == 1 ? false : true">
        <el-input v-model.number="formList.integral" placeholder="请输入积分"></el-input>
      </el-form-item>
      <el-form-item label="游戏ID">
        <el-input
          v-model="formList.gameId"
          placeholder="购票成功后是否参与游戏活动  0 为不参与"
        ></el-input>
      </el-form-item>
      <el-form-item label="销售平台">
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0"></div>
        <el-checkbox-group v-model="checked" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="item in platform" :label="item.value" :key="item.value">{{
            item.name
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="供应商">
        <el-select v-model="formList.supplierId" placeholder="请选择">
          <el-option
            v-for="item in supplierOptions"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="结算价">
        <el-input v-model="formList.settlePrice" placeholder="请输入结算价"></el-input>
      </el-form-item>
      <el-form-item label="票付通参数">
        <el-input
          v-model="formList.pftInfo"
          placeholder="供应商aid|景区lid|门票tid"
        ></el-input>
      </el-form-item>
      <el-form-item label="预定须知" prop="ticketBookNotice">
        <!-- <quill-editor
          class="richEdit"
          :options="quillOption"
          v-model.trim="formList.ticketBookNotice"
        >
        </quill-editor> -->
        <VueUeditorWrap
          v-model="formList.ticketBookNotice"
          :config="myConfig"
          ref="myTextEditor"
        ></VueUeditorWrap>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="onSave">保存</el-button>
    <el-button type="primary" @click="$router.go(-1)">返回</el-button>
  </div>
</template>
<script>
const platform = [
  { value: 1, name: "小程序" },
  { value: 2, name: "H5" },
  { value: 3, name: "IOS" },
  { value: 4, name: "Android" },
  { value: 5, name: "PC" },
];
import { sceneryList } from "../../api/sceneryList";
import { addTicket, ticketDetails, allCate } from "../../api/ticket";
import quillConfig from "../../../quill-config";
import { Quill } from "vue-quill-editor";
import VueUeditorWrap from "vue-ueditor-wrap";
import { couponAllCateAPI } from "../../api/coupon";
import { supList } from "../../api/supList.js";
import SendImage from "../../components/sendImage.vue";
export default {
  name: "addTicket",
  components: {
    VueUeditorWrap,
    SendImage,
  },
  data() {
    return {
      supplierOptions: [{ cateId: 0, cateName: "自营" }],
      checkAll: false,
      checked: [],
      checkVal: [],
      platform: platform,
      myConfig: {
        toolbars: [
          [
            "simpleupload", //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 400, // 初始容器高度
        initialFrameWidth: "100%", // 初始容器宽度
        //  serverUrl: "/api/config",
        serverUrl: "/config", //打包
        //          UEDITOR_HOME_URL: "/UEditor/",
        UEDITOR_HOME_URL: "/static/UEditor/", //打包
        maximumWords: "100000000",
      },
      quillOption: quillConfig,
      sceneryValue: "",
      sceneryOptions: [],
      sceneryOptions1: [],
      allCate: [],
      ticketImage: "",
      formList: {
        cateId: "",
        ticketId: Number(this.id),
        sceneryId: null,
        ticketName: "",
        ticketSecondName: "",
        ticketLabel: "",
        sellWay: "",
        integral: null,
        ticketBookNotice: "",
        gameId: "",
        pftInfo: "",
        supplierId: "",
        settlePrice: "",
        showImg: "",
        isShow: 1,
        isUser: 0,
        shareImg: "",
        unsubscribe:1,
        wlShow:1,
        isNew:0
      },
      sellWayList: [
        {
          value: 1,
          label: "全金额",
        },
        {
          value: 2,
          label: "全积分",
        },
        {
          value: 3,
          label: "积分+金额",
        },
      ],
      rules: {
        ticketName: [{ required: true, message: "请输入主标题", trigger: "blur" }],
        ticketSecondName: [{ required: true, message: "请输入副标题", trigger: "blur" }],

        integral: [{ required: true, message: "请输入积分", trigger: "blur" }],
        ticketLabel: [{ required: true, message: "请输入标签", trigger: "blur" }],
        ticketBookNotice: [
          { required: true, message: "请输入预定须知", trigger: "blur" },
        ],
      },
    };
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  mounted() {
    quillConfig.register(Quill);
    quillConfig.initButton();
  },
  created() {
    this.getSceneryList();
    this.init();
    // this.getCate();
    this.getSupplierList();
    this.getAllCate();
    console.log(this.id, "id");
    if (this.id !== "0") {
      this.getTicketDetails();
    }
  },
  methods: {
    getSupplierList() {
      supList().then((res) => {
        this.supplierOptions.push(...res.data.data);
      });
    },
    addImageStr(image) {
      this.formList.showImg = image;
     
    },
    addImageStrShare(image) {
      this.formList.shareImg = image;
    
    },
    addImageStrs(image) {
      // console.log(1,1)
      this.ticketImage = image;
    },
    async getAllCate() {
      const { data } = await allCate();
      console.log(data, "getallcate");
      this.allCate = data.data;
    },
    // 编辑-- 获取门票详情
    async getCate() {
      const { data } = await couponAllCateAPI();
      let arr = data.data;
      arr.map((e) => {
        this.supplierOptions.push({ supplierId: e.cateId, label: e.cateName });
      });
    },
    init() {
      for (let i = 0; i < this.platform.length; i++) {
        this.checkVal.push(this.platform[i].value);
      }
    },
    handleCheckAllChange(val) {
      this.checkVal = [];
      if (val) {
        console.log("刷新页面");
        this.init();
        console.log("if", this.checkVal);
        console.log(this.checked, "checked");
        this.checked = this.checkVal;
        this.formList.sellPlace = "0";
      } else {
        this.checked = [];
      }
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.platform.length;
      this.formList.sellPlace = this.checked.toString();
      console.log(value, "value");
    },
    async getTicketDetails() {
      const { data } = await ticketDetails({
        ticketId: this.id,
      });
      if (data.code !== 0) {
        return;
      }
      this.formList = data.data[0];
      this.formList.supplierId = data.data[0].supplierId;
      this.formList.settlePrice = data.data[0].settlePrice;
      this.formList.sellPlace = data.data[0].sellPlace;
      this.formList.cateId = data.data[0].cateId;
      this.formList.showImg = data.data[0].showImg;
      this.formList.isShow = data.data[0].isShow;
      this.formList.isUser = data.data[0].isUser || 0;
      this.formList.shareImg = data.data[0].shareImg || '';
      this.formList.wlShow = data.data[0].wlShow || 1;
      this.formList.isNew = data.data[0].isNew || 0;
      console.log( this.formList, "this.formList.showImg");
      try {
        let arr = data.data[0].sellPlace.split(",");
        let newArr = [];
        arr.map((e) => {
          newArr.push(Number(e));
        });
        if (newArr[0] == 0 || newArr.length == this.platform.length) {
          this.checkAll = !this.checkAll;
          console.log(this.checkVal, "val");
          this.checked = this.checkVal;
        } else {
          this.checkVal = newArr;
          this.checked = this.checkVal;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async onSave() {
      if (this.formList.sellWay > 1 && this.formList.integral === null) {
        return this.$message({
          message: "在全积分和积分+金额情况下,积分为必填项",
          type: "error",
        });
      }
      this.$refs.form.validate(async (validate) => {
        if (validate && this.formList.sceneryId) {
          if (this.formList.sellWay === 1) {
            //全金额
            if (!this.formList.cateId) {
              this.$message({
                message: "请把数据填充完整",
                type: "error",
              });
              return;
            }
            const { data } = await addTicket({
              ticketId: this.formList.ticketId,
              sceneryId: this.formList.sceneryId,
              ticketName: this.formList.ticketName,
              ticketSecondName: this.formList.ticketSecondName,
              ticketLabel: this.formList.ticketLabel,
              sellWay: this.formList.sellWay,
              ticketBookNotice: this.formList.ticketBookNotice,
              gameId: this.formList.gameId,
              pftInfo: this.formList.pftInfo || "0",
              settlePrice: this.formList.settlePrice,
              supplierId: this.formList.supplierId,
              sellPlace: this.formList.sellPlace,
              cateId: this.formList.cateId,
              showImg: this.formList.showImg,
              isShow: this.formList.isShow,
              isUser: this.formList.isUser,
              shareImg: this.formList.shareImg,
              unsubscribe:this.formList.unsubscribe,
              wlShow:this.formList.wlShow,
              isNew:this.formList.isNew,
            });
            if (data.code !== 0) {
              this.$message({
                message: data.msg,
                type: "error",
              });
            } else {
              this.$message({
                message: data.msg,
                type: "success",
              });
              this.$router.go(-1);
            }
          } else {
            if (!this.formList.cateId) {
              this.$message({
                message: "请把数据填充完整",
                type: "error",
              });
              return;
            }
            const { data } = await addTicket(this.formList);
            if (data.code !== 0) {
              this.$message({
                message: data.msg,
                type: "error",
              });
            } else {
              this.$message({
                message: data.msg,
                type: "success",
              });
              this.$router.go(-1);
            }
          }
        } else {
          if (!this.formList.cateId) {
            this.$message({
              message: "请把数据填充完整",
              type: "error",
            });
            return;
          }
          this.$message({
            message: "请把数据填充完整",
            type: "error",
          });
        }
      });
    },
    //获取景区分类
    async getSceneryList() {
      const { data } = await sceneryList({
        currentPage: 1,
        pageSize: 1000,
      });
      console.log(data);
      this.sceneryOptions = data.list;
    },
    onClear() {
      this.formList.sceneryId = null;
    },
  },
};
</script>
<style lang="less" scoped>
.addTicket {
  .showImg {
    .el-form-item__content {
      display: flex;
      flex-wrap: wrap;
      .img {
        position: relative;
        height: 100px;
        width: 100px;
        margin: 20px 20px;
        img {
          height: 100px;
          width: 100px;
          border: 1px solid #c0ccda;
        }
        div {
          position: absolute;
          top: 0;
          right: 2px;
          height: 10px;
          width: 10px;
          z-index: 100;
        }
      }
      .addImg {
        display: inline-block;
        width: 100px;
        height: 100px;
        border: 1px dashed #c0ccda;
        text-align: center;
        line-height: 100px;
        cursor: pointer;
        margin-top: 20px;
        i {
          width: 50px;
          height: 50px;
          font-size: 20px;
          color: #c0ccda;
        }
      }
    }
  }
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/ .el-form {
    margin-top: 50px;
    .el-form-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .el-form-item__label {
      width: 8.875rem !important;
      text-align: center !important;
    }
    .el-form-item__content {
      width: 800px;
      margin-left: 0 !important;
      .quill-editor {
        height: 500px;
        .ql-container {
          height: 400px;
        }
      }
    }
  }
}
</style>
